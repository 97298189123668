.title {
	opacity: 0.75;
	color: #071013;
	font-family: Roboto;
	font-size: 18px;
	line-height: 27px;
}

.price {
	opacity: 0.75;
	color: #071013;
	font-family: Roboto;
	font-size: 18px;
	font-weight: bold;
	line-height: 27px;
}
