.customButton {
  padding-top: 16px;
  padding-bottom: 16px;
  font-family: Roboto, serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #FF595E;
  width: 100%;
  height: auto;
  border: 1px solid #F1F2F5;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 5px 6px -3px rgba(7,16,19,0.1);
}