.colItem {
  width: 20%;
  margin-bottom: 15px;
}

.books > div > div > div {
  display: flex;
  flex-wrap: wrap;
}

.menuSubTitle {
  display: inline-block;
  color: #292929;
  font-family: Roboto, serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 25px;
  margin-bottom: 16px;
}
.dropDownTitle {
  color: #888A8C;
  font-family: Roboto;
  font-size: 16px;
  line-height: 40px;
}

:global .digitizationMenu .ant-menu-item-group-title {
  color: #838789;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  line-height: 12px;
  text-transform: uppercase;
}
:global .digitizationMenu .ant-menu-item {
  color: #888A8C;
  font-family: Roboto;
  font-size: 16px;
  line-height: 40px;
}
:global .digitizationMenu .ant-menu-item.ant-menu-item-selected {
  font-weight: bold;
  color: #292929;
}
:global .digitizationMenu .ant-menu-item-group-list .indentedMenuItem.ant-menu-item {
  display: inline-block;
  padding-left: 45px !important;
  font-size: 15px;
}
@media screen and (min-width:991px) {
  :global .bookList .ant-row .ant-col {
    flex-basis: 20%;
    width: 20%;
  }
}