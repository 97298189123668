.card {
  /* min-height: 260px; */
}
.coverImg {
  width: 100%;
  height: 188px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0 10px 15px -4px rgba(7, 16, 19, 0.2);
  border-radius: 4px;
  margin-bottom: 15px;
}

.city {
  width: 100%;
  font-family: "Tinos";
  color: #071013;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
}

@media screen and (max-width: 991px) {
  .coverImg {
    height: 155px;
  }
  .card {
    min-height: 225px;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.image {
  height: 15vh; 
  width: 30vw;
  background-size: cover;
  background-position: center;
}

@media screen and (max-width: 991px) {
  .image {
    height: 30vh;
    width: 100vw;
  }
}