.actions {
	display: flex;
	justify-content: center;
}

.actionItem {
	margin: 10px;
	height: 40px;
	width: 40px;
	border-radius: 20px;
	background-color: #ffffff;
	box-shadow: 0 5px 6px -3px rgba(7, 16, 19, 0.1);
	display: inline-block;
	line-height: 40px;
	text-align: center;
	color: #ff595e;
	cursor: pointer;
}

:global .carous > div > div > .slick-slide {
	transition: all .2s ease-in-out;
	transform: scale(.9);
}
:global .carous > div > div > .slick-active {
	transition: all .2s ease-in-out;
	transform: scale(1.1);
}

.pageBtn {
	height: 40px;
	width: 40px;
	border-radius: 20px;
	background-color: #ffffff;
	box-shadow: 0 5px 6px -3px rgba(7, 16, 19, 0.1);
	display: inline-block;
	line-height: 40px;
	text-align: center;
	color: #ff595e;
	cursor: pointer;
}

.card {
	transition: all 0.2s ease-in-out;
}
.cardActive {
	transform: scale(1);
}
.heading {
	margin-left: 2vw;
  width: 100vw;
  font-family: 'Tinos', serif;
  font-size: 20px;
  font-weight: bold;
  line-height: 25px;
  text-align: left;
  color: #292929;
}

@media screen and (max-width: 576px) {
	.heading {
		text-align: center;
	}
}

.imageWrapper {
	position: relative;
	display: flex;
	height: 70%;
	background-size: cover;
	/* padding-bottom: 100%; */
	background-color: #e5e5e5;
	/* background-size: 100%; */
}

.imageBackdrop {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
	background: rgba(43, 174, 208, 0.65);
}

.featuredImageInfo {
	margin: auto;
	z-index: 5;
	padding: 10px;
}

.featuredImageDay {
	opacity: 0.8;
	color: #ffffff;
	font-family: Roboto;
	font-size: 12px;
	font-weight: 900;
	line-height: 15px;
	text-transform: uppercase;
}

.featuredImageDate {
	/* height: 60px; */
	color: #ffffff;
	font-family: 'Tinos';
	font-size: 20px;
	line-height: 30px;
	text-transform: capitalize;
}
.featuredImageDivider {
	display: inline-block;
	height: 4px;
	width: 100px;
	background-color: white;
}
.featuredImagePlace {
	color: #ffffff;
	font-family: Roboto;
	font-size: 16px;
	line-height: 24px;
}

.activityInfo {
	background-color: #f7f6f3;
	height: 30%;
	padding: 5px;
	text-align: center;
	overflow: auto;
}

.title {
	color: #071013;
	font-family: 'Tinos';
	font-size: 16px;
	font-weight: bold;
	line-height: 25px;
}
.speakers {
	color: #292929;
	font-family: Roboto;
	font-size: 14px;
	font-weight: 900;
	margin: 0;
}
.paragraph {
	color: #292929;
	font-family: Roboto;
	font-size: 14px;
	line-height: 27px;
}

.link {
	margin: 20px auto;
	display: block;
	text-align: center;
	width: 150px;
}
