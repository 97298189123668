.title {
	font-size: 30px;
	color: #071013;
    margin-bottom: 30px;
    text-align: left;
}

.dateAndTime {
	border-bottom: 1px dashed rgb(7, 16, 19, .5);
	padding-bottom: 15px;
	display: flex;
	align-items: center;
}
.dateAndTime i,
.location i {
	margin-right: 9px;
	font-size: 18px;
}
.time,
.address {
	opacity: 0.75;	
	color: #071013;	
	font-family: 'Roboto', sans-serif;	
	font-size: 15px;	
	line-height: 22px;
}
.time span:first-of-type {
	margin-right: 5px;
}

.location {
	display: flex;
	align-items: center;
	padding-top: 9px;
}

.address {
	word-wrap: break-word;
}

.subTitle {
	color: #071013;	
	font-family: 'Tinos', serif;	
	font-size: 25px;	
	font-weight: bold;	
	line-height: 30px;
	margin-bottom: 25px;
}

.bookTitle {
	color: #071013;	
	font-family: 'Tinos', serif;	
	font-size: 22px;	
	font-weight: bold;	
	line-height: 25px;
	margin-top: 30px;
}

.bold {
	opacity: 0.75;	
	color: #071013;	
	font-family: 'Roboto', sans-serif;	
	font-size: 18px;	
	font-weight: 900;	
	line-height: 24px;
}

.infoSectionCaption {
	opacity: 0.75;	
	color: #071013;	
	font-family: 'Roboto', sans-serif;	
	font-size: 18px;	
	line-height: 27px;
}

.clockIcon,
.pinIcon {
	/* position: relative;
	bottom: 5px;
	display: inline-block;
	margin-right: 15px;
	font-size: 24px; */
}

.coverPhoto {
    margin-bottom: 15px;
}

.coverPhoto img {
    display: block;
    width: 100%;
    max-width: 600px;
    height: auto;
    margin: auto;
}

.bookTitle {
	font-size: 22px;
	font-weight: bold;
}

.block {
	margin-bottom: 30px;
}

.about,
.subscribe {
	color: #071013;	
	font-family: 'Tinos', serif;	
	font-size: 24px;	
	font-weight: bold;	
	line-height: 30px;
}

.share {
	opacity: 0.5;	
	color: #071013;	
	font-family: 'Roboto', sans-serif;	
	font-size: 16px;	
	line-height: 21px;
	text-align: center;
	border-top: 1px solid #dcdcdc;
	padding-top: 30px;
}

.btnSubscribe {
	padding: 15px 20px;
	margin-top: 30px;
	height: auto;
}
.btnSubscribe span {
	color: #FFFFFF;	
	font-family: 'Roboto', sans-serif;	
	font-size: 15px;	
	font-weight: bold;	
	line-height: 18px;
}

.shareIcon,
.socialMedia {
	font-size: 25px
}

.socialMedia svg {
	margin-right: 5px !important;
}