.back-to {
    display:inline-block;
    margin-bottom: 0.7rem;
}

.informations {
    display: flex;
    flex-direction: row;
}

.info-item-container {
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
}

.info-item {
    margin-right: 10px;
    text-transform: uppercase;
}

.item-info {
    color: gray !important;
}

.arrow-back {
    font-size: 0.7rem;
    margin-right: 0.5rem;
}

.location-page-address {
    display: flex;
    flex-direction: column;
}

.location-page-title {
    margin-bottom: 2rem;
}

.location-info {
    padding: 0;
    width: 100%;
}

.location-info-card-container {
    margin-left: 1rem;
    margin-top: 1rem;
    width: 100%;
}

.location-card-title {
    font-weight: bold;
    color: black;
    margin-bottom: 2rem;
}

.container-info {
    display: flex;
    flex-direction: column;
}
@media(max-width: 590px) {
    .informations {
    flex-direction: column !important;
    justify-content: center;
    align-items: flex-start;
    }
    .location-info-card-container {
        margin-left: 0;
        margin-top: 0;
    }
    .info-item-container{
        margin-left: 0;
    }

    .location-card-title {
        margin: 1rem 0 0.6rem 0 !important;
    }
}
