.title {
    color: #292929;
    font-family: "Tinos";
    font-size: 32px !important;
    line-height: 40px;
    font-weight: normal !important;
}

.point {
	color: #292929;
	font-family: Roboto;
	font-size: 18px;
    line-height: 27px;
    padding-left: 15px;
    position: relative;
}

.point:before {
    content: '•';
    color: #FF595E;
    font-size: 11px;
    position: absolute;
    top: -2px;
    left: 0;
}