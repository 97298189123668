:global .dropdownMenu {
  top: 113px !important;
}
:global .dropdownMenu .ant-dropdown-menu {
  padding: 0;
}
:global .dropdownMenu .ant-dropdown-menu-submenu-title {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 40px;
  font-weight: bold;
}
:global .dropdownMenu .ant-dropdown-menu-submenu-title:hover {
  background-color: #FF595E;
  color: white;
}
:global .dropdownMenu .ant-dropdown-menu-item {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 40px;
  font-weight: bold;
}
:global .dropdownMenu .ant-dropdown-menu-item:hover {
  background-color: #FF595E;
}
:global .dropdownMenu .ant-dropdown-menu-item a {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 40px;
  font-weight: bold;
}
:global .dropdownMenu .ant-dropdown-menu-item a:hover {
  color: white;
}
:global .digiSub > .ant-dropdown-menu {
  padding: 0;
}
:global .digiSub > .ant-dropdown-menu .ant-dropdown-menu-submenu-title {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 40px;
  font-weight: bold;
}
:global .digiSub > .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover {
  background-color: #FF595E;
  color: white;
}
:global .digiSub > .ant-dropdown-menu > .ant-dropdown-menu-item:hover {
  background-color: #FF595E;
}
:global .digiSub > .ant-dropdown-menu > .ant-dropdown-menu-item > a {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 40px;
  font-weight: bold;
}
:global .digiSub .ant-dropdown-menu-submenu-title:hover .ant-dropdown-menu-submenu-arrow-icon {
  color: white;
  font-weight: bold;
}
:global .digiSub .ant-dropdown-menu-submenu-title span:first-of-type {
  display: inline-block;
  min-width: 115px;
}
:global .digiSub .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  margin-left: 15px;
}
:global .digiSub > .ant-dropdown-menu > .ant-dropdown-menu-item > a:hover {
  color: white;
}
:global .dropdownMenu .ant-dropdown-menu-submenu-title:hover .ant-dropdown-menu-submenu-arrow-icon {
  color: white;
  font-weight: bold;
}

.main_dropdown_element:after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  display: block;
  border-left: 1.5px solid rgba(0, 0, 0, 0.3);
  border-top: 1.5px solid rgba(0, 0, 0, 0.3);
  width: 7px;
  height: 7px;
  position: absolute;
  right: 1.5rem;
  transform: translate(50%, -50%) rotate(135deg);
  font-size: 12px;
}

