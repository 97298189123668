.bigLetter{
    font-family: 'Tinos', serif;
	color:#071013;
	font-size: 20px;
}

.friendsName{
    font-family: 'Roboto';
	font-size: 18px;
}

.accordionWrapper{
   background-color:white;
}

.friend{
    background-color:white;
}

.header{
    font-family: 'Roboto-semibold', serif;
    font-size: 18px;
}
