.layout {
    background-color: #fff;
    width: 100%;
    margin: 0 auto;
    padding: 30px 0 50px;
}

@media screen and (min-width: 992px) {
    .layout {
        max-width: 1200px;
        padding-top: 50px;
    }
}