.actions {
	display: flex;
	justify-content: center;
}

.actionItem {
	margin: 10px;
	height: 40px;
	width: 40px;
	border-radius: 20px;
	background-color: #ffffff;
	box-shadow: 0 5px 6px -3px rgba(7, 16, 19, 0.1);
	display: inline-block;
	line-height: 40px;
	text-align: center;
	color: #ff595e;
	cursor: pointer;
}

@media screen and (max-width: 991px) {
  .heading {
    margin-left: 2vw;
    width: 100vw;
    font-family: 'Tinos', serif;
    font-size: 20px;
    font-weight: bold;
    line-height: 25px;
    text-align: left;
    color: #292929;
    margin-top: 40px;
    margin-bottom: 0;
  }
  
  .contentWrapper {
    display: block;
    margin-top: -30px;
  }

  .singleActivityWrapper {
    display: block;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    transition: all 250ms ease-in-out;
  }

  :global .active {
    transform: scale(1.08);
  }
  :global .inactive {
    transform: scale(0.9);
  }

  .activityImageWrapper {
    text-align: center;
    position: relative;
    top: 75px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 200px;
    width: 200px;
    margin: auto;
    box-shadow: 0px 1px 10px 1px rgba(0,0,0,.5);
  }

  .activityImageWrapper img {
    margin: auto;
    box-shadow: 0px 1px 10px 1px rgba(0,0,0,.5);
  }

  .activityDescriptionWrapper {
    background-color: #00A1C5;
    text-align: left; 
    padding: 100px 15px 20px;
    height: 315px;
  }
  .activityDescriptionWrapper * {
    color: #fff
  }
  .activityDescriptionWrapper h1 {
    font-family: 'Tinos',serif;
    font-weight: 400; 
    font-style: normal;
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 20px;
    color: #fff;
  }
  .dateTime p {
    font-family: 'Roboto', sans-serif;
    color: #fff;
    opacity: 0.8;
    font-size: 12px;
    font-weight: 900;
    line-height: 15px;
    margin-bottom: 0;
  }
  .dateTime h2 {
    font-family: 'Tinos', serif;
    font-size: 24px;
    line-height: 30px;
    min-height: 30px;
    font-weight: 400;
  }
}

@media screen and (max-width: 576px) {
	.heading {
    width: 100vw;
		text-align: center;
	}
}