.expandIconSvg {
  width: 12px;
  height: 12px;
}
.expandIcon {
  color: red;
  transform-origin: center center;
  transition: all 1s ease-in;
}
.open {
  transform: rotate(0deg);
}
.closed {
  transform: rotate(90deg);
}

/* @keyframes iconopen {
  0% {
    width: 12px;
    opacity: 1;
  }
  100% {
    width: 0;
    opacity: 0;
  }
}
@keyframes iconclose {
  0% {
    width: 0;
    opacity: 0;
  }
  100% {
    width: 12;
    opacity: 1;
  }
} */

