.singleActivityHeader {
    padding: 0 20px;
}

.backBtn {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    line-height: 24px;
    padding: 0;
}
.backBtn i {
    font-size: 14px;
}