.layout {
  background-color: #fff;
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 0;
}
.content {
  margin-left: 60px;
}
:global .ant-menu-inline {
  border-right: none;
}
:global .ant-menu-inline .ant-menu-item > a:hover {
  color: #000 !important;
}
:global .ant-menu-inline .ant-menu-item-selected > a {
  color: #000 !important;
}
:global .ant-menu-inline .ant-menu-item-selected {
  background-color: transparent !important;
}
:global .ant-menu-inline .ant-menu-item-selected::after {
  content: "";
  width: 9px;
  height: 9px;
  border-top: 2px solid #ff595e;
  border-right: 2px solid #ff595e;
  margin-right: 12px;
  margin-top: 16px;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}
:global .ant-menu-inline .ant-menu-item::after {
  -moz-transition: none;
  -webkit-transition: none;
  -o-transition: color 0 ease-in;
  transition: none;
}
@media screen and (max-width: 992px) {
  .layout {
    padding: 20px;
  }
  .content {
    margin-left: 0;
  }
  .locationSidebar {
    display: none;
  }
}
