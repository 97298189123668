:global .titleWrapper {
  display: flex;
  border-bottom: 3px solid #F1F2F5;
  padding-top: 30px;
  padding-bottom: 10px;
}
:global .yearSelect {
  /* margin-left: 20px; */
  font-family: "Tinos", serif;
  color: #071013;
  font-size: 24px;
  font-weight: bold;
  line-height: 25px;
  text-align: right;
}
:global .yearSelect .ant-select-selection {
  border: none;
}
:global .yearSelect .ant-select-selection .ant-select-arrow {
  right: 5px;
}
:global .titleWrapper h3.ant-typography {
  margin-bottom: 0;
  color: #071013;
  font-family: "Tinos", serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 25px;
}
.accordionIcon > i {
	background-color: red;
}
.iconWrapper > .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
	display: flex;
	align-items: center;
}
.header {
	font-size: 18px;
	line-height: 22px;
	color: black;
}
.text {
  opacity: 0.75;	
  color: #071013;	
  font-family: Roboto;	
  font-size: 18px;	
  line-height: 27px;
}
:global .procurementItem > .ant-collapse-content > .ant-collapse-content-box {
  padding-left: 10px;
  padding-right: 0;
}
.procurementItemContent {
  display: flex;
  flex-direction: column;
}
:global .accordionWrapper .ant-collapse-item .ant-collapse-header {
  padding-left: 0;
}
.procurementItemContent a {
  opacity: 0.75;	
  color: #071013;	
  font-family: Roboto;	
  font-size: 16px;	
  line-height: 24px;
  text-decoration: underline;
}

@media screen and (max-width: 992px) {
	.accordionItem .ant-collapse-header {
		padding-left: 0;
  }
  .accordionItem > div:first-of-type {
		padding-left: 0;
  }
  .accordionIcon.anticon {
		color: red;
		right: 0;
	}
}