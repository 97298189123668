.procuredTitles__dropdown {
  display: flex;
}

.procuredTitles__meseci {
  display: none;
}

.procuredTitles__headingSection {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1em;
	border-bottom: 1px solid #f1f2f5;
}
.procuredTitles__heading {
	height: 25px;
	width: 360px;
	color: #071013;
	font-family: "Tinos";
	font-size: 20px;
	font-weight: bold;
	line-height: 25px;
}
.procuredTitles__row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1em;
	border-top: 1px solid #f1f2f5;
}
.procuredTitles__info {
	width: 360px;
	opacity: 0.75;
	color: #071013;
	font-family: Roboto;
	font-size: 18px;
	line-height: 20px;
}
.actions {
	display: flex;
	justify-content: center;
}
.actionItem {
	margin: 10px;
}

.procuredTitles__checkboxGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
  overflow: auto;
  height: 240px;
}

.procuredTitles__checkboxGroup::-webkit-scrollbar {
  width: 6px;
}
.procuredTitles__checkboxGroup::-webkit-scrollbar-button {
  display: none;
}
.procuredTitles__checkboxGroup::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #E4E5E9;
}

.procuredTitles__checkboxGroup__checkbox {
  padding: 8px 0;
  opacity: 0.75;
  color: #071013;
  font-family: Roboto;
  font-weight: bold;
}

.procuredTitles__loadmore {
  margin-top: 32px;
  padding-top: 16px;
  padding-bottom: 16px;
  font-family: Roboto, serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #FF595E;
  width: 100%;
  height: auto;
  border: 1px solid #F1F2F5;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 5px 6px -3px rgba(7,16,19,0.1);
}

@media screen and (max-width: 576px) {
  .procuredTitles__dropdown {
    flex-direction: column;
  }

  .procuredTitles__meseci {
    display: inline-block;
    color: #071013;
    font-family: "Tinos";
    font-size: 20px;
    font-weight: bold;
    line-height: 25px;
    margin-bottom: 31px;
  }

  .procuredTitles__headingSection {
    display: none;
  }

  .procuredTitles__row {
    flex-direction: column;
    align-items: flex-start;
    padding: 4px 0;
  }
  .procuredTitles__row__authgen {
    width: 100%;
    margin: 0;
    text-align: right;
  }
  .procuredTitles__row__authgen > span {
    float: left;
    opacity: 0.75;
    color: #071013;
    font-family: "Tinos";
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
  }

  .procuredTitles__row__title {
    margin: 0;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}