.bookItemActive {
	cursor: pointer;
	display: flex;
	animation: fadeIn 2.2s;
}

.mobileItem {
	height: 500px;
}

.bookItemInactive {
	cursor: pointer;
	position: relative;
}

.imageContainer {
	margin: 0 auto;
	box-shadow: 0 10px 15px -5px rgba(7, 16, 19, 0.2);
}

.titleInactive {
	color: #071013;
	font-family: "Tinos";
	font-size: 20px;
	line-height: 25px;
	font-weight: bold;
	margin-bottom: 0;
	word-wrap: wrap;
}

.author {
	opacity: 0.5;
	color: #071013;
	font-family: Roboto;
	font-size: 17px;
	line-height: 25px;
	margin-bottom: 18px;
}

.text {
	opacity: 0.75;
	color: #071013;
	font-family: Roboto !important;
	font-size: 18px !important;
	line-height: 27px !important;
	margin: 1rem 0 1.5rem 0 !important;
	padding-right: 4px;
	overflow-y: scroll;
	height: 200px;
}

.text::-webkit-scrollbar {
  width: .25rem;
}
 
.text::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}
 
.text::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.ganre {
	color: rgba(0, 0, 0, 0.2);
	font-size: 16px;
	text-transform: uppercase;
	font-weight: 700;
	margin-bottom: 0;
}

.actions {
	display: flex;
	justify-content: center;
}

.actionItem {
	margin: 10px;
	height: 40px;
	width: 40px;
	border-radius: 20px;
	background-color: #ffffff;
	box-shadow: 0 5px 6px -3px rgba(7, 16, 19, 0.1);
	display: inline-block;
	line-height: 40px;
	text-align: center;
	color: #ff595e;
	cursor: pointer;
}

.indexActive {
	position: absolute;
	margin-top: 0 !important;
	top: -5%;
	right: 0;
	z-index: -2;
	color: rgba(7, 16, 19, 0.03) !important;
	font-family: "Adobe Caslon Pro" !important;
	font-size: 150px !important;
	font-weight: 600;
	line-height: 180px !important;
}

.indexInactive {
	position: absolute;
	margin-top: 0 !important;
	font-family: "Adobe Caslon Pro" !important;
	top: -5%;
	right: -2%;
	z-index: -2;
	color: rgba(7, 16, 19, 0.03) !important;
	font-size: 90px !important;
	font-weight: 600 !important;
	line-height: 108px !important;
	text-align: right !important;
}

.index {
	position: absolute;
	margin-top: 0 !important;
	top: -5%;
	right: 0;
	z-index: -2;
	opacity: 0.08;
	font-family: "Adobe Caslon Pro";
	font-size: 120px !important;
	font-weight: 600;
	line-height: 144px !important;
}

.details {
	margin-left: 20px;
}

.info {
	position: relative;
	margin-top: 10px;
	padding: 0 10px;
}

@media only screen and (min-width: 768px) {
	.mobileItem {
		width: 80vw;
		margin-right: 5vw;
		display: flex;
		flex-direction: row;
		height: 400px;
	}
	.author {
		margin: 10px 0;
	}
	.indexMobile {
		position: absolute;
		margin-top: 0 !important;
		top: -5%;
		right: 0;
		z-index: -2;
		opacity: 0.08;
		font-family: "Adobe Caslon Pro";
		font-size: 120px !important;
		font-weight: 600;
		line-height: 144px !important;
	}
	
}

@media only screen and (min-width: 768px) and (max-width: 900px) {
	.info {
		margin-left: 5%;
	}
}

@media only screen and (max-width: 767px) {
	.mobileItem {
		width: 80vw;
		margin-right: 5vw;
		display: flex;
		flex-direction: column;
	}

	.imageContainer {
		display: block;
		box-shadow: 0 16px 16px -8px rgba(7, 16, 19, 0.3);
	}

	.info {
		padding: 0;
	}

	.indexMobile {
		position: absolute;
		margin-top: 0 !important;
		top: -5%;
		right: 0;
		z-index: -2;
		opacity: 0.08;
		font-family: "Adobe Caslon Pro";
		font-size: 120px !important;
		font-weight: 600;
		line-height: 144px !important;
	}

	.imageContainer {
		margin: 0;
	}

	.ganre {
		font-size: 11px;
		font-weight: 900;
		line-height: 13px;
	}

	.text {
		opacity: 0.75;
		color: #071013;
		font-family: Roboto;
		font-size: 15px !important;
		line-height: 21px !important;
		height: 100px;
	}
	.title {
		font-size: 24px !important;
		line-height: 24px !important;
		margin: 10px 0;
	}

	.author {
		margin: 10px 0;
		font-size: 14px !important;
		line-height: 20px !important;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
