.noResults {
    min-height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
  }
  
  .noResults span {
    padding-top: 10px;
    font-size: 21px;
    color: #000;
    font-weight: 500;
  }