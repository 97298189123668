@media only screen and (max-width: 991px) {
  .menu {
    border-right: none;
  }

  .menu .menuItem:not(:last-of-type) {
    border-bottom: 1px solid #f3f4f6;
  }
  .menu .menuItem.menuItemAboutUs {
    border-bottom: 3px solid #F1F2F5;
  }

  .menu .menuItemTitle {
    text-transform: uppercase;
    font-size: 16px;
    color: #444b4d;
    font-weight: 600;
    padding-bottom: 10px;
    height: auto;
  }

  :global .ant-menu-root.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
  }

  .menuItem.menuItemTitle.menuItemLinkWrapper > a {
    font-weight: 600;
    color: #444b4d;
  }

  .menuItemTitle > div:hover {
    color: inherit;
    background-color: transparent;
  }

  #menuItemDigitalizacija.menuItemTitle > div i:before,
  #menuItemDigitalizacija.menuItemTitle > div i:after {
    width: 12px;
  }
  #menuItemDigitalizacija.menuItemTitle > div i:before {
    transform: rotate(45deg) translateX(5px);
  }
  #menuItemDigitalizacija.menuItemTitle > div i:after {
    transform: rotate(-45deg) translateX(-5px);
  }

  .subMenuGroupTitle {
    text-transform: none;
  }
  .subMenuGroupTitle>:hover,
  .subMenuGroupTitle>:active {
    color: #454c4e;
    font-weight: 700;
  }

  .subMenuGroupTitle.singleItem {
    padding-left: 30px !important;
  }

  .subMenuGroupTitle > div:hover,
  .subMenuGroupTitle > div:active {
    background-color: transparent;
  }

  .menuItemLinkWrapper > a {
    font-weight: 400;
  }

  .menuItemLinkWrapper:hover,
  .menuItemLinkWrapper:active {
    background-color: transparent;
  }

  .menuItemLinkWrapper > a:hover,
  .menuItemLinkWrapper > a:active {
    color: #454c4e;
    font-weight: 700;
  }

  .menu .subMenuGroupTitle,
  .menu .menuItemLink {
    color: #292929;
    font-weight: 400;
  }

  .mobileMenuFooterItem.mobileMenuAdditionalItem {
    border-radius: 4px;	
    background-color: #F7F6F3;
    padding: 15px 0 15px 15px !important;
  }
  .mobileMenuFooterItem.mobileMenuAdditionalItem a {
    opacity: 0.5;	
    color: #071013;	
    font-family: 'Roboto', sans-serif;	
    font-size: 16px;	
    line-height: 19px;
  }
  .mobileMenuFooterItem.mobileMenuAdditionalItem i {
    font-size: 22px;
    vertical-align: middle;
  }

  :global .ant-menu-item,
  :global .ant-menu-submenu-title {
    height: auto !important;
  }

  :global .ant-menu-submenu.ant-menu-submenu-active > .ant-menu-submenu-title {
    font-weight: 400;
  }
  :global ul:not(.ant-menu-root) .ant-menu-submenu.ant-menu-submenu-open.ant-menu-submenu-active > .ant-menu-submenu-title {
    font-weight: 700;
  }
  :global .ant-menu-submenu.ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
  :global .ant-menu-submenu.ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after {
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.65)), to(rgba(0, 0, 0, 0.65)));
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65));
  }
  :global .ant-menu-submenu.ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
  :global .ant-menu-submenu.ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after {
    background: -webkit-gradient(linear, left top, right top, from(#FF595E), to(#FF595E));
    background: linear-gradient(to right, #FF595E, #FF595E);
  }

  :global .ant-menu-root > .ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
  :global .ant-menu-root > .ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after {
    width: 12px;
  }
  :global .ant-menu-root > .ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before {
    transform: rotate(-45deg) translateX(5px);
  }
  :global .ant-menu-root > .ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after {
    transform: rotate(45deg) translateX(-5px);
  }
  :global .ant-menu-root > .ant-menu-submenu-inline.ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before {
    transform: rotate(45deg) translateX(5px);
  }
  :global .ant-menu-root > .ant-menu-submenu-inline.ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after {
    transform: rotate(-45deg) translateX(-5px);
  }
  
  :global .ant-menu.ant-menu-sub .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow {
    right: 16px;
  }
  :global .ant-menu.ant-menu-sub .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
  :global .ant-menu.ant-menu-sub .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow:after {
    width: 9px;
  }

  :global .ant-menu.ant-menu-sub .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
    /* transform: rotate(-45deg) translateX(4px); */
    left: 3px;
  }
  /* :global .ant-menu.ant-menu-sub .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
    transform: rotate(45deg) translateX(-4px);
  }
  :global .ant-menu.ant-menu-sub .ant-menu-submenu-inline.ant-menu-submenu-open .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
    transform: rotate(45deg) translateX(4px);
  }
  :global .ant-menu.ant-menu-sub .ant-menu-submenu-inline.ant-menu-submenu-open .ant-menu-submenu-title .ant-menu-submenu-arrow:after {
    transform: rotate(-45deg) translateX(-4px);
  } */
}
