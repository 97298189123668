.currentPage.mobile {
  display: none;
}
:global .digitizedModal.ant-modal {
  top: 0;
  padding-bottom: 0;
}
:global .digitizedModal.ant-modal .ant-modal-content {
  background-color: rgba(255,255,255,0.95);
}
.singlePage {
  width: 100%;
}
.singlePage img {
  width: 100%;
  height: auto;
  max-height: 560px;
  object-fit: contain;
}
.carouselRow {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 160px;
}
:global .carousel-wrapper {
  position: relative;
}
:global .carousel-wrapper .ant-btn.ant-btn-circle {
  box-sizing: border-box;
  height: 41px;
  width: 41px;
  border: 1px solid #F1F2F5;
  border-radius: 20px;
  background-color: #FFFFFF;
  box-shadow: 0 5px 6px -3px rgba(7,16,19,0.1);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
:global .carousel-wrapper .ant-btn.ant-btn-circle:first-of-type {
  left: -30px;
}
:global .carousel-wrapper .ant-btn.ant-btn-circle:last-of-type {
  right: -30px;
}
:global .carousel-wrapper .ant-btn.ant-btn-circle .anticon {
  color: red;
}
.previewWrapper {
  text-align: center;
}
:global .previewCarousel img {
  display: block;
  height: 100px;
  max-width: 120px;
  margin: auto;
  object-fit: contain;
}
:global .ant-carousel .previewCarousel .slick-list .slick-slide:not(:last-child) {
  padding: 0 2px;
}
.currentPage {
  display: inline-block;
  padding: 10px 0;
  opacity: 0.75;
  color: #071013;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  line-height: 27px;
}
.bookTitle {
  color: #292929;
  font-family: Tinos, serif;
  font-size: 32px;
  line-height: 40px;
}
.details {
  margin-bottom: 20px;
}
.details p {
  display: inline-block;
  width: 70px;
  color: #838789;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 5px;
}
.details span {
  opacity: 0.75;
  color: #071013;
  font-family: Roboto;
  font-size: 16px;
  line-height: 27px;
}
.description p {
  opacity: 0.75;
  color: #071013;
  font-family: Roboto;
  font-size: 18px;
  line-height: 27px;
  max-height: 300px;
  overflow-y: scroll;
}
.customCloseIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
}
:global .digitizedModal.ant-modal .ant-modal-close {
  right: 25px;
  top: 25px;
}
:global .digitizedModal.ant-modal .ant-modal-close-x {
  position: relative;
}
.customCloseIcon::before {
  content: '';
  display: block;
  position: absolute;
  top: 10px;
  width: 20px;
  height: 2px;
  background-color:#071013;
  transform: rotate(45deg) ;
}
.customCloseIcon::after {
  content: '';
  display: block;
  position: absolute;
  top: 10px;
  width: 20px;
  height: 2px;
  background-color:#071013;
  transform: rotate(-45deg) ;
}

.removeAllStyles div {
  transform : none !important;
  color: red;
}

@media screen and (max-width: 1200px) {
  :global .carousel-wrapper .ant-btn.ant-btn-circle:first-of-type {
    left: 0px;
  }
  :global .carousel-wrapper .ant-btn.ant-btn-circle:last-of-type {
    right: 0px;
  }
}
@media screen and (max-width: 991px) {
  .currentPage.mobile {
    display: block;
  }
  .carouselRow {
    min-height: 100vh;
    display: block;
    padding: 0 15px 15px;
  }
  .previewWrapper {
    display: none;
  }
  .singlePage img {
    width: 100%;
    height: auto;
    max-height: 260px;
    object-fit: contain;
  }
  :global .digitizedModal.ant-modal {
    margin: 0;
    max-width: 100vw;
  }
  :global .digitizedModal.ant-modal .ant-modal-close {
    right: 0;
    top: 0;
  }
  .currentPage.mobile {
    text-align: center;
    padding-top: 16px;
  }
  :global .carousel-wrapper {
    position: relative;
  }
  :global .carousel-wrapper .ant-btn.ant-btn-circle:first-of-type {
    position: absolute;
    left: 30%;
    top: unset;
    bottom: 0;
  }
  :global .carousel-wrapper .ant-btn.ant-btn-circle:last-of-type {
    position: absolute;
    right: 30%;
    top: unset;
    bottom: 0;
  }
}

@media screen and (max-width: 767px) {

}