.container {
  max-width: 1200px;
  margin: 0 auto; 
}

.right {
  margin-left: auto;
}

.inner {
  align-items: center;
  display: flex;
  padding: 20px 0;
}

.search {
  background-color: #2A3743;
  padding: 10px 0;
}

.nav ul {
  border-bottom: none;
  text-align: right;
}

.nav button {
  display: inline;
}

.mainNav ul {
  border-bottom: none;
  text-transform: uppercase;
  font-weight: 900;
}

.mainNav ul {
  border-bottom: none;
  text-transform: uppercase;
  font-weight: 900;
}

:global .ant-menu-horizontal { font-weight: bold; }
:global .ant-menu-horizontal > .ant-menu-item-selected > a { color: #000; }
:global .ant-menu-horizontal > .ant-menu-item-selected > span {
  color: #000;
  padding: 13px 0;
}

@media only screen and (max-width: 930px) {
  .search {
    padding: 10px 20px;
  }
}

@media only screen and (max-width: 768px) {
  .mobileHidden {
    display: none;
  }
}