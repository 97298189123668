.container {
	position: relative;
	display: flex;
	cursor: pointer;
}

.title {
	height: 52px;
	color: #071013;
	font-family: "Tinos" !important;
	font-size: 30px !important;
	font-weight: bold;
	word-wrap: wrap;
	margin-bottom: 20px !important;
	line-height: 25px !important;
}
.author {
	opacity: 0.5;
	color: #071013;
	font-family: Roboto !important;
	font-size: 17px !important;
	line-height: 25px !important;
}

.img {
	height: 300px;
	width: auto;
	margin: 0 auto;
	box-shadow: 0 10px 15px -5px rgba(7, 16, 19, 0.2);
}

.text {
	opacity: 0.75;
	color: #071013;
	font-family: Roboto;
	font-size: 18px !important;
	line-height: 27px !important;
	/* margin: 1em 0 0 !important; */
	overflow-y: scroll;
	height: 200px;
}

.text::-webkit-scrollbar {
  width: .25rem;
}
 
.text::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}
 
.text::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.ganreRow {
	margin-top: 0;
}

.ganre {
	height: 17px;
	width: 301px;
	opacity: 0.2;
	color: #071013;
	font-family: Roboto;
	font-size: 14px;
	font-weight: 900;
	line-height: 17px;
}

.recommendedBy {
	color: #071013;
	font-family: Roboto !important;
	font-size: 14px !important;
	font-weight: 900 !important;
	line-height: 17px !important;
	opacity: .75;
}

.actions {
	margin-top: 30px;
	display: flex;
	justify-content: center;
}

.actionItem {
	margin: 10px;
	height: 40px;
	width: 40px;
	border-radius: 20px;
	background-color: #ffffff;
	box-shadow: 0 5px 6px -3px rgba(7, 16, 19, 0.1);
	display: inline-block;
	line-height: 40px;
	text-align: center;
	color: #ff595e;
	cursor: pointer;
}

.infoContainer {
	position: relative;
	transition: width 0.3s;
}

.info {
	margin-left: 5%;
	flex: 2;
}

.bckDrop {
	position: absolute;
	margin-top: 0 !important;
	top: 0;
	right: 0;
	z-index: -2;
	font-family: "Adobe Caslon Pro";
	opacity: 0.08;
}

@media only screen and (max-width: 991px) {
	.container {
		width: 80vw;
		margin-right: 5vw;
	}

	.img {
		height: 300px;
		margin: 0;
	}

	.text {
		font-size: 15px;
		line-height: 21px;
		height: 100px;
	}
	.author {
		margin-top: 0 !important;
		font-size: 14px;
		line-height: 20px;
	}

	.title {
		font-size: 24px !important;
		font-weight: bold;
		line-height: 24px !important;
		margin-bottom: 0 !important;
	}
	.ganreRow {
		margin-top: 10px;
	}
}

@media only screen and (max-width: 767px) {
	.container {
		flex-direction: column;
	}

	.title {
		font-size: 24px !important;
		line-height: 24px !important;
		margin-bottom: 20px !important;
	}

	.img {
		height: 250px;
		margin: 0;
	}

	.info {
		margin: 20px 0 0 0;
	}
	.author {
		margin-top: 20px;
	}
}
