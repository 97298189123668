.container {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 16px;
}

.bottom {
  margin-top: 60px;
}

.inner {
  display: flex;
}

.item {
  width: 20%;
}

.item:not(:last-child) {
  padding-right: 20px;
}

.bottom {
  display: flex;
  justify-content: space-between;
}

.social {
  margin: 0;
  padding: 0;
}

.social li {
  display: inline-block;
  margin-left:10px;
  font-size:16px
}

.socialBox {
  display: flex;
  font-family: 'Roboto', serif;
}

.header {
  color: #071013;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  text-transform: uppercase;
}
.paragraf {
  color: #071013;
  font-family: Roboto;
  font-size: 14px;
  line-height: 24px;
  opacity: 0.5;
}

.footerLink {
    opacity: 0.5;
    color: #071013;
    font-family: Roboto;
    font-size: 14px;
    line-height: 30px;
}

.list {
  list-style: none;
  padding-left: 0;
}

.mail {
  color: #071013 !important;
}

.cc {
    color: #AAAAAA;
    font-family: Roboto;
    font-size: 12px;
    line-height: 21px;
}