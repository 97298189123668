.usefulLinkWrapper {
  display: flex;
  border-radius: 8px;
  background-color: #F7F6F3;
  padding: 30px 40px 30px 295px;
  position: relative;
  margin-top: 30px;
}
.usefulLinkWrapper:not(:last-child) {
  margin-bottom: 50px;
}
.usefulLinkImg {
  width: 225px;
  height: 150px;
  position: absolute;
  top: -30px;
  left: 30px;
  
}
h3.mainTitle {
  color: #292929;	
  font-family: Tinos, serif;	
  font-size: 30px;	
  font-weight: bold;	
  line-height: 40px;
}
.usefulLinkDesc h4.descTitle{
  font-family: Tinos, serif;
  color: #071013;
  font-size: 24px;
  font-weight: bold;
  line-height: 30px;
}
.usefulLinkDesc .descContent {
  opacity: 0.75;
  color: #071013;
  font-family: Roboto;
  font-size: 18px;
  line-height: 27px;
}
.usefulLinkImg img {
  object-fit: cover;
  height: 150px;
  width: 225px;
  border-radius: 8px;
}
@media screen and (max-width: 991px) {
  .usefulLinkWrapper {
    flex-direction: column;
    padding: 150px 20px 30px 20px;
    margin-top: 50px;
    margin-bottom: 40px;
  }
  .usefulLinkImg {
    left: 50%;
    transform: translateX(-50%)
  }
}