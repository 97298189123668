.selectedTitle {
	font-size: 25px;
    font-weight: 400;
	font-family: "Tinos";
	margin-bottom: 32px;
	color: #000;
}

.styledCol {
	width: 20%;
}

.allTitles {
	text-transform: uppercase;
	color: rgba(0, 0, 0, 0.65);
	font-size: 12px;
	font-weight:bold;
	font-family: "Tinos";
	height: 23px;
}
.allTitles:hover {
	color: #000;
	border-bottom: 2px solid #FF595E;
}

/* @media screen and (max-width: 400px) {
	.styledCol {
		margin: 0 auto 3rem auto;
		width: 48vw;
		margin-bottom: 24px;
	}
} */
@media screen and (max-width: 992px) {
	.booksrow {
		justify-content: space-evenly;
		overflow: scroll;
		/* flex-flow: row; */
	}

	.styledCol > div {
		/* width:44vw; */
		width: 40vw;
		margin-bottom: 16px;
	}

	.styledCol {
		width: auto;
		/* display: flex;
		justify-content: center;
		width: 48vw;
		margin-bottom: 24px; */
		/* margin: 0 auto 3rem auto; */
		/* align-items: center; */
	}
} 