:global .mobileFooterWrapper > ul {
  padding-right: 20px;
  padding-left: 20px;
}

:global .mobileFooterWrapper > ul > li > .ant-menu-submenu-title {
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
  color: rgb(39, 30, 30);
  padding-top: 10px;
  padding-bottom: 10px;
}

:global .subMenuItem > .ant-menu-sub {
  padding-left: 16px;
  padding-bottom: 10px;
}

:global .subMenuItem > .ant-menu-sub > .ant-menu-item {
  margin-bottom: 4px;
}

:global .subMenuItem > .ant-menu-sub > .ant-menu-item a {
  font-size: 16px;
  color:#071013;
  opacity: 0.5;
}

:global .subMenuItem > .ant-menu-sub > .ant-menu-item::after {
  border: unset;
}

:global .subMenuItem > .ant-menu-sub > .ant-menu-item.ant-menu-item-selected {
  background-color: transparent;
}

:global .subMenuItem > .ant-menu-sub > .ant-menu-item.ant-menu-item-selected > a,
:global .subMenuItem > .ant-menu-sub > .ant-menu-item.ant-menu-item-selected > a:hover {
  color: rgba(0, 0, 0, 0.65);
}

:global .subMenuItem.ant-menu-submenu > .ant-menu-submenu-title {
  line-height: 40px;
  font-size: 16px;
  padding-top: 0;
  padding-bottom: 6px;
  color:#071013;
}

:global .subMenuItem.ant-menu-submenu > .ant-menu-submenu-title:active {
  background-color: transparent;
}

:global .subMenuItem {
  border-bottom: 1px solid #f3f4f6;
  line-height: 50px;
  margin-bottom: 6px;
}

:global .subMenuItem .menu-submenu-title:active {
  background-color: transparent;
}

:global .subMenuItem.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  right: 6px;
  top: 20px;
}

:global .subMenuItem.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
  transform: rotate(0deg);
  height: 2px;
  background-image: linear-gradient(to right, #FF595E, #FF595E);
}

:global .subMenuItem.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  transform: rotate(90deg);
  height: 2px;
  background-image: linear-gradient(to right, #FF595E, #FF595E);
}

:global .subMenuItem.ant-menu-submenu-inline.ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
  transform: rotate(0deg) translateY(2px);
}

:global .subMenuItem.ant-menu-submenu-inline.ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  transform: rotate(0deg) translateY(2px);
}

.contact {
  display: flex;
  flex-direction: column;
  padding: 0 20px 16px 20px;
}

.contactTitle {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
  color: rgb(39, 30, 30);
  line-height: 40px;
  padding-bottom: 10px;
}

.contactText {
  white-space: pre;
  font-size: 16px;
  /* line-height: 30px; */
  opacity: 0.5;
}

:global .social {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 30px 0;
  font-size: 16px;
  opacity: 0.5;
}

:global .social .anticon {
  font-size: 32px;
  opacity: 0.5;
}

.socialMenu {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.socialMenuItem {
  margin-left: 15px;
}

.copy {
  padding: 20px 0;
}

:global .copyText {
  color: #AAAAAA;
  font-size: 13px;
  line-height: 24px;
}
