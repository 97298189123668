@media screen and (max-width: 991px) {
  .loadingInProgress {
    text-align: center;
    margin-top: 50px;
  }
  
  .singleResultRow > td {
    border-bottom: none;
    font-family: 'Tinos', serif;
  }

  .singleResultRow:hover > td {
    background-color: transparent !important;
  }

  .singleResultRow + tr img {
    max-width: 200px;
  }

  .searchResultsWrapper ul li a {
    font-size: 12px;
  }

  :global .ant-pagination.ant-table-pagination {
    margin-right: 25px;
  }
  :global .ant-table-thead > tr > th, 
  :global .ant-table-tbody > tr > td {
    padding-right: 9px;
    padding-left: 9px;
  }
  :global .ant-table .ant-table-content .ant-table-expand-icon-th, 
  :global .ant-table .ant-table-content .ant-table-row-expand-icon-cell {
    padding-right: 5px;
    padding-left: 5px;
  }
}
