.container {
  display: inline-flex;
  align-items: center;
}

.logo {
  display: inline-block;
  font-size: 20px;
  color: #071013;
  text-transform: uppercase;
  margin: 0 0 0 10px;
  width: 250px;
}

@media screen and (max-width: 992px) { 
  .logoImage {
    width: 50px;
  }

  .logo {
    font-size: 14px;
    width: 160px;
    font-weight: 600;
  }
}