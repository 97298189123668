.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wrapper:not(:last-of-type) {
    border-bottom: 1px solid #f1f2f5;
}

.title {
    opacity: 0.4;
    color: #071013;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: bold;
    line-height: 15px;
}

.name {
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 18px !important;
    line-height: 22px;
}

.contact {
    cursor: pointer;
    height: 31px;
    width: 91px;
    border: 1px solid #F1F2F5;
    border-radius: 5px;
    background-color: #FFFFFF;
    box-shadow: 0 5px 6px -3px rgba(7,16,19,0.1);
    color: #FF595E;
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    line-height: 31px;
    text-align: center;
}

@media screen and (max-width: 991px) {
    .wrapper > div:first-of-type {
        width: 60%;
    }

    .title {
        display: block;
        line-height: 17px;
        margin-bottom: 10px;
    }

    .contact {
        padding: 16px 29px;
        height: auto;
        width: auto;
    }
}