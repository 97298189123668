.card {
	box-shadow: 0 10px 15px -5px rgba(7, 16, 19, 0.1);
	border-radius: 4px;
	overflow: hidden;
	margin: 15px 0;
}

.image {
	display: block;
	width: 100%;
	height: 225px;
	background-size: cover;
	background-position: center center;
}

.text {
	padding: 10px 16px;
	background-color: white;
}

.category {
	font-family: "Roboto", sans-serif;
	font-size: 13px;
	line-height: 16px;
	opacity: 0.5;
	color: #071013;
	text-transform: uppercase;
	font-weight: 900;
	display: inline-block;
	margin-bottom: 5px;
}

.title {
	font-family: Tinos, serif;
	color:#071013;
	font-size: 26px;
	line-height: 32px;
	font-weight: 700;
	min-height: 60px;
}
.description {
	color: #292929;
	font-family: "Roboto", serif;
	font-size: 20px;
	line-height: 27px;
	opacity: 0.6;
}
.desc {
	color: #292929;
}
@media screen and (max-width: 991px) {
	.card {
		transition: all 0.3s ease-out;
	}
	.text {
		padding: 14px 20px;
		background-color: white;
	}
	.image {
		height: 290px;
	}
	:global .popularActivityContent > .ant-typography:first-of-type {
		margin-bottom: 10px;
	}
	:global .popularActivityContent > .ant-typography:last-of-type {
		margin-bottom: 0px;
	}
}
