.singleBook {
  margin-top: 20px;
}

.singleBook__linkback {
  color: #FF595E;
  font-family: Roboto;
  font-size: 1rem;
  line-height: 1.5;
}

.singleBook__wrapper {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}


.singleBook__image {
  margin-bottom: 20px;
  height: 272px;
  width: 178px;
  background-position: center;
  background-size: cover;
  -webkit-box-shadow: 0px 32px 30px -26px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 32px 30px -26px rgba(0,0,0,0.75);
  box-shadow: 0px 32px 30px -26px rgba(0,0,0,0.75);
}

.singleBook__image__info {
  width: 178px;
}

.singleBook__image__line {
  display: flex;
  justify-content: space-between;
}

.singleBook__image__line p {
  margin: 0;
  height: 20px;
  color: #838789;
  font-family: Roboto;
  font-size: 1rem;
  font-weight: bold;
  line-height: 20px;
}

.singleBook__image__line span {
  opacity: 0.75;
  color: #071013;
  font-family: Roboto;
  font-size: 16px;
  line-height: 27px;
}

.singleBook__info__genre {
  opacity: 0.2;
  color: #071013;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 900;
  line-height: 17px;
  text-transform: uppercase;
}

.singleBook__info__title {
  color: #071013;
  font-family: "Tinos";
  font-size: 30px;
  font-weight: bold;
  line-height: 40px;
}

.singleBook__info__author {
  opacity: 0.5;
  color: #071013;
  font-family: Roboto;
  font-size: 17px;
  line-height: 25px;
}

.singleBook__info__recommended > span {
  opacity: 0.2;
  color: #071013;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 900;
  line-height: 17px;
  text-transform: uppercase;
}

.singleBook__info__description {
  opacity: 0.75;
  color: #292929;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}

.singleBook__info__description > p {
  width: 686px;
  opacity: 0.75;
  color: #071013;
  font-family: Roboto;
  font-size: 18px;
  line-height: 27px;
}

.share {
  font-size: 14px;
  position: absolute;
  left: 80%;
  top: 15%;
	text-align: right;
	opacity: 0.5;
}

.shareIcon,
.socialMedia {
	font-size: 25px;
}

@media (max-width: 576px) {
  .share {
    position: relative;
    text-align: center;
    left: 0;
    padding-bottom: 24px;
  }

  .singleBook__row {
    display: flex;
    flex-direction: column;
  }

  .singleBook__wrapper {
    margin-left: 3vw;
  }

  .singleBook__image {
    height: 350px;
    width: 230px;
  }

  .singleBook__info__genre {
    margin-top: 24px;
  }

  .singleBook__info__title {
    width: 85vw;
  }

  .singleBook__info__description > p {
    width: 85vw;
  }
}