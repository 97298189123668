.hasResults {
  position: absolute;
  max-height: 315px;
  background-color: #fff;
  top: 126%;
  z-index: 2;
  box-shadow: 0px 1px 7px 5px rgba(0,0,0,.5);
  width: 98%;
  overflow-y: auto;
  padding: 15px 12px;
}

.dropdownClosed {
  display: none;
}

.dropdownOpen {
  display: block;
}

.searchResultWrapper{
  padding-bottom: 5px;
}
.searchResultWrapper:not(:first-of-type) {
  padding-top: 5px;
}
.searchResultWrapper:hover {
  cursor: pointer;
}

.singleSearchResult {
  display: inline-block;
}

.prefName {
  font-weight: 400;
  color: #737373;
  margin-left: 10px;
}