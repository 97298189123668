.filtersWrapper {
	font-family: 'Roboto', sans-serif;
}

.btnCloseFilters {
	display: none;
}

.searchGroupName {
	display: inline-block;
	width: 100%;
	margin: 25px 0 0 0;
	border-bottom: 1px solid #F1F2F5;
}

.searchCategory {
	display: block;
	margin-bottom: 3px;
	color: #838789;
	font-size: 10px;
	font-weight: 900;
	letter-spacing: 1px;
	line-height: 12px;
	text-transform: uppercase;	
	font-family: 'Roboto', sans-serif;
}

.filtersOverlaySearch i {
	font-size: 20px;
}

:global .ant-radio-inner {
	width: 14px;
	height: 14px;
}
:global .ant-radio-wrapper-checked .ant-radio-inner {
	background-color: #ff595e;
}

:global .ant-radio-wrapper-checked span:not(.ant-radio) {
	color: #292929;
	font-weight: bold;
}

.searchGroup label {
	display: block;
	margin: 15px 0 0 0 !important;
	font-size: 15px;
}

.count {
	color: #071013;	
	font-family: 'Roboto', sans-serif;	
	font-size: 14px;	
	line-height: 25px;
	opacity: 0.5;	
}

.searchGroup .ant-checkbox-group,
.searchGroup .ant-checkbox-wrapper {
	width: 100%;
}

.reset {
	border: none;
    padding-left: 0;
    height: auto;
}

.reset span {
	margin-top: 25px;
	opacity: 0.75;
	color: #292929;
	font-size: 18px;
	line-height: 25px;
	text-decoration: underline;
}
.reset:hover span {
	color: #000;
}

.datePicker {
	margin-top: 15px;
}
.datePicker i {
	color: #071013;
	opacity: 0.75;
}

.from,
.to {
	display: inline-block;
	margin-right: 5px;
	font-size: 18px;
	line-height: 25px;
}

.count {
	position: absolute;
	right: 0;
}

@media screen and (max-width: 991px) {
	.filtersWrapper {
		padding: 20px;
		box-sizing: border-box;
	}

	.titleWrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 5px;
		border-bottom: 3px solid #F1F2F5;
		margin-bottom: 20px;
	}
	
	.titleWrapper h1 {
		display: inline-block;
		color: #292929;	
		font-family: "Tinos", serif;	
		font-size: 32px;	
		line-height: 40px;
		margin-bottom: 0;
	}
	
	.btnCloseFilters {
		display: inline-block;
	}

	.filtersWrapper.filtersOverlay input {
		padding: 21px 15px 20px 15px;
	}
	.filtersWrapper.filtersOverlay input[type=radio] {
		margin-right: 10px;
	}
	.filtersWrapper input::placeholder {
		color: #838789;	
		font-family: 'Roboto', sans-serif;	
		font-size: 16px;	
		line-height: 19px;
	}

	.searchGroup {
		margin-bottom: 30px;
	}
	.searchGroup label {
		display: flex;
		align-items: center;
	}
	.searchGroup label > span:last-of-type {
		font-size: 18px;
	}

	.count {
		font-size: 17px;
	}

	.searchCategory {
		font-size: 14px;	
		letter-spacing: 1.4px;	
		line-height: 17px;
	}

	.from,
	.to {
		margin-right: 16px;
	}

	.datePicker {
		display: flex;
		align-items: center;
	}
	.datePicker > span:last-child {
		display: block;
		width: 100%;
	}

	.reset {
		margin-top: 10px;
	}

	.btnApplyFilters {
		background-color: #FF595E;
		border-radius: 6px;
		color: #fff;
		font-family: 'Roboto', sans-serif;
		font-size: 15px;
		font-weight: bold;
		padding: 13px 0;
		height: auto;
	}
}