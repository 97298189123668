@media screen and (max-width: 991px) {
  .mobileHidden {
    display: none;
  }

  :global .mobileSearchInputField.ant-input-search input {
    border: none;
  }
  :global
    .mobileSearchInputField.ant-input-search
    input::-webkit-input-placeholder {
    color: rgba(0, 5, 143, 0.5);
  }
  :global .mobileSearchInputField.ant-input-search input::-moz-placeholder {
    color: rgba(0, 5, 143, 0.5);
  }
  :global .mobileSearchInputField.ant-input-search input:-moz-placeholder {
    /* Older versions of Firefox */
    color: rgba(0, 5, 143, 0.5);
  }
  :global .mobileSearchInputField.ant-input-search input:-ms-input-placeholder {
    color: rgba(0, 5, 143, 0.5);
  }
  :global .mobileSearchInputField.ant-input-search input::placeholder {
    color: #838789;
  }

  :global .mobileSearchInputField .ant-input-search-button {
    background-color: #fff;
    border: none;
    box-shadow: none;
    text-shadow: none;
  }
  :global .mobileSearchInputField .ant-input-search-button .anticon-search {
    color: #5f5f5f;
    font-size: 24px;
    position: relative;
    top: 2px;
  }

  :global .autocomplete-suggestions {
    display: none;
  }
  .hasResults {
    display: block;
    background-color: #fff;
    margin-top: 10px;
    border-radius: 5px;
    padding: 10px;
    max-height: 50vh;
    width: 94%;
    position: absolute;
    z-index: 2;
    box-shadow: 1px 1px 21px 1px rgba(0, 0, 0, 0.5);
    overflow-y: auto;
  }

  .hasResults > div {
    margin-bottom: 15px;
  }
  .hasResults span:first-of-type {
    margin-right: 5px;
  }

  .dropdownClosed {
    display: none;
  }
}
