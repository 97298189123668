.card {
  min-height: 335px;
}

.image {
  display: block;
  max-width: 100%;
  height: auto;
  box-shadow: 0 10px 15px -5px rgba(7,16,19,0.2);
}

.title {
  color: #071013;
  font-family: "Tinos";
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  margin-top: 20px;
  cursor: pointer;
}

.secondary {
  opacity: 0.5;	
  color: #071013;	
  font-family: Roboto;	
  font-size: 15px;	
  line-height: 20px;
}
.secondaryLabel {
  font-family: Roboto;
  font-weight: bold;
  color: #838789;
}
.coverImg {
  width: 100%;
  height: 220px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0 10px 15px -4px rgba(7, 16, 19, 0.2);
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  .card {
    width: 160px;
    min-height: 325px;
  }
  .coverImg {
    height: 220px;
  }
}