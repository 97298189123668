.logoWrapper > a {
  display: block;
}

@media only screen and (min-width: 992px) {
  .mobileNavMenu {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .mobileNavMenu {
    padding: 35px 20px 15px;
  }

  .mobileNavMenu.menuOpen {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    z-index: 5;
    overflow-y: auto;
  }

  .mobileNavMenu.menuOpen + div {
    display: none;
  }

  .container {
    max-width: 100%;
    margin: 0 auto;
  }

  .logoWrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
  }

  .navMenuIcon {
    padding-top: 5px;
  }
  .navMenuIcon span,
  .navMenuIcon:before,
  .navMenuIcon:after {
    display: block;
    content: '';
    width: 27px;
    height: 3px;
    border-radius: 25%;
    background-color: #292929;
    margin: 6px 0;
    transition: all .5s ease-in-out;
  }
  .menuOpen .navMenuIcon:before {
    transform: translateY(9px) rotate(135deg);
  }
  
  .menuOpen .navMenuIcon:after {
    transform: translateY(-9px) rotate(-135deg);
  }
  
  .menuOpen .navMenuIcon span {
    transform: scale(0);
  }

  .displayNone {
    display: none;
  }

  .divider {
    height: 3px;
    background-color: rgba(241, 242, 245);
    box-shadow: 0px 0px 22px 0px rgb(222, 222, 222);
  }

  .mobileSearchWrapper {
    background-color: #2a3743;
    padding: 20px;
  }
}
