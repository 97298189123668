.title {
	font-size: 32px;
	color: #292929;
	margin-bottom: 50px;
	font-family: 'Tinos', serif;
}

.activityInfo {
	font-family: 'Roboto', sans-serif;
}

.subTitle,
.about,
.subscribe {
	font-size: 30px;
	font-weight: bold;
	margin-bottom: 30px;
	font-family: 'Tinos', serif;
}

.subscribe {
	margin-top: 30px;
}

.btnSubscribe {
	font-size: 14px;	
	font-weight: bold;	
	line-height: 17px;
	padding: 10px 20px;
	height: auto;
	margin: 15px 0;
}

.dateAndTime,
.location {
	display: inline-block;
	width: 50%;
}

.dateAndTime {
	padding-right: 20px;
	border-right: 1px dashed #b7b7b7;
}

.location {
	display: inline-flex;
	padding-left: 15px;
}

.clockIcon,
.pinIcon {
	position: relative;
	bottom: 5px;
	display: inline-block;
	margin-right: 15px;
	font-size: 24px;
}

.address,
.time {
	display: inline-block;
	font-family: 'Roboto', sans-serif;
}

.address {
	position: relative;
	bottom: 10px;
	word-wrap: break-word;
}

.coverPhoto {
	width: 500px;
	height: 500px;
	background-size: cover;
	background-position: cover cover;
}

.bookTitle {
	font-size: 22px;
	font-weight: bold;
	font-family: 'Tinos', serif;
	line-height: 25px;
}

.block {
	margin-bottom: 30px;
}

.bold {
	font-weight: bold;
	margin-bottom: 5px;
}

.infoSectionCaption {
	opacity: 0.75;	
	color: #071013;
	font-size: 18px;
	line-height: 27px;
}

.share {
	font-size: 14px;
	position: absolute;
	top: 5px;
	right: 15px;
	text-align: right;
	opacity: 0.5;
}

.shareIcon,
.socialMedia {
	font-size: 25px;
}

.socialMedia svg {
	margin-right: 5px !important;
}