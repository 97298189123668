.card {
  overflow: hidden;
  position: relative;
  max-height: 474px;
  margin-bottom: 60px;
}

.image:hover,
.title:hover {
  cursor: pointer;
}

.image {
  display: block;
  max-width: 100%;
  height: 250px;
  background-size: cover;
  background-position: center center;
  border-radius: 8px;
}

.title.singleItemTitle {
  font-family: "Tinos", serif;
  font-weight: 700;
  font-size: 32px;
  margin-top: 15px;
}

.desc {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  opacity: 0.75;	
  color: #071013;
  line-height: 27px;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

@media screen and (min-width: 992px) {
  .card {
    max-width: 260px;
    height: 415px;
    margin-bottom: 25px;
  }

  .title.singleItemTitle {
    font-size: 20px;
    margin-top: 20px;
    line-height: 25px;
  }
}