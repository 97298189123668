.wrapper {
    color: #292929;
    font-family: Roboto;
    font-size: 11px;
    font-weight: 900;
    letter-spacing: 1.1px;
    line-height: 13px;
    border-bottom: 3px solid #FF595E;
    padding-bottom: 5px;
    display: inline-block;
    margin-right: 30px;
    height: 22px;
}

.link {
    color: hsla(195, 46%, 5%, .5);
    text-transform: uppercase;
    
}

.wrapper:hover {
    color: #FF595E;
}