.locationContainer {
	font-family: 'Tinos', serif;
}

:global .locationMenu .ant-menu-item-group-title {
  color: #838789;	
  font-family: Roboto;	
  font-size: 10px;	
  font-weight: 900;	
  letter-spacing: 1px;	
  line-height: 12px;
  text-transform: uppercase;
}
:global .locationMenu .ant-menu-item {
  color: #888A8C;	
  font-family: Roboto;	
  font-size: 16px;	
  line-height: 40px;
}
:global .locationMenu .ant-menu-item.ant-menu-item-selected {
  font-weight: bold;
  color: #292929;
}
:global .locationMenu .ant-menu-item-group-list .indentedMenuItem.ant-menu-item {
  display: inline-block;
  padding-left: 45px !important;
  font-size: 15px;
}

.menuSubTitle {
  display: inline-block;
  color: #292929;
  font-family: Roboto, serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 25px;
  margin-bottom: 16px;
}

.streetmap {
	height: 720px;
	width: 100%;
	margin: 0;
	padding: 0;
	border-radius: 10px;
}
@media screen and (max-width: 576px) {
	.streetmap {
		height: 335px;
		width: 90%;
		margin: 0 auto;
	}
}

.buttonGroup {
	display: flex;
	justify-content: flex-end;
	transition: 0ms !important;
}

.checkbox {
	font-size: 16px;
	color: #888a8c;
	margin-bottom: 7px;
}
.customIcon {
	width: 12px;
	height: 12px;
}
.customIcon svg {
	color: #FF595E;
}
.p {
	font-size: 16px;
	margin-bottom: 2px;
}
.accordionRegularText {
	color: black;
}
.workTime {
	flex: 1;
	font-weight: bold;
  font-size: 16px;
  color: black;
}

.locationInfo {
	flex: 1;
}

.workTimeLabel {
	min-width: 103px;
}
.workTimeDay {
	display: flex;
}
.workTimeDay > .p > * {
	color: black;
}
.panelContainer {
	display: flex;
	width: 75%;
	padding: 0 24px;
}

.locationContainer {
	background: #fff;
	padding: 24px;
	margin: 0;
	min-height: 280px;
}
.iconWrapper > .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
	display: flex;
	align-items: center;
}

.iconText {
	font-size: 14px;
	margin-right: 8px;
	line-height: 16px;
	margin-bottom: 2px;
}
.header {
	font-size: 18px;
	line-height: 22px;
	color: black;
}
.mobileTitle h3 {
	display: none;
}
.toggleButton {
	animation-duration: 0s !important;
}
.mapWrapper {
	height: 100vh;
	max-height: 850px;
	width: 100%;
	padding-top: 10px;
}
.mapMarkerInner {
	left: 9px;
	bottom: 9px;
	position: absolute;
	height: 13px;
	width: 13px;
	/* background-color: #FF595E; */
	background-color: #00a1c5;
	border-radius: 50%;
	opacity: 0.9;
}

.markerContainer {
	position: relative;
}

.mapMarkerOuter {
	left: 50%;
	align-items: center;
	height: 32px;
	width: 32px;
	opacity: 0.3;
	background-color: #00a1c5;
	border-radius: 50%;
}

/* animation-duration: 0s !important;
} */
.toggleButtonActive {
	background: black !important;
	border-color: black !important;
	color: white !important;
}

.toggleButtonDisabled {
	background: #f6f6f8 !important;
	border-color: #f6f6f8 !important;
	color: gray !important;
}
.accordionIcon > i {
	background-color: red;
}

:global .mobileTitleRow .ant-btn {
	height: 50px;
	padding: 0 20px;
	font-size: 16px;
}

.language {
	font-family: 'Roboto', serif;
	margin:5px; 
	padding:5px; 
	height: 200px; 
	overflow: auto; 
}

@media screen and (max-width: 1200px) {
	.panelContainer {
		width: 100%;
	}
}
@media screen and (max-width: 992px) {
	.panelContainer {
		padding-left: 0;
		flex-direction: column;
	}
	.accordionItem .ant-collapse-header {
		padding-left: 0;
	}
	.locationContainer {
		padding: 10px 0 24px;
	}
	.locationContainer .accordionItem > div:first-of-type {
		padding-left: 0;
	}
	.workTime {
		margin-left: 0;
	}
	.accordionIcon.anticon {
		color: red;
		right: 0;
	}
	:global .mobileTitleRow {
		align-items: center;
	}
	:global .locationItem > .ant-collapse-content > .ant-collapse-content-box {
		padding-left: 10px;
		padding-right: 0;
	}
	.mobileTitle h3 {
		display: block;
		font-size: 30px;
		font-weight: 400;
		font-family: "Tinos";
		margin-bottom: 0;
	}
	.iconText {
		display: none;
	}
	.mapWrapper {
		max-width: 990px;
		max-height: 700px;
	}
}
@media screen and (max-width: 768px) {
	.mapWrapper {
		max-height: 60vh;
	}
}
@media screen and (max-width: 400px) {
	.mapWrapper {
		max-height: 50vh;
	}
}
@media screen and (max-width: 360px) {
	.workTimeLabel {
		min-width: 90px;
	}
	.workTimeDay {
		min-width: 200px;
	}
	.p {
		font-size: 14px;
	}
}
