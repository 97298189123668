.galleryModal {
  height: 100vh;
}

.preview {
  display: flex;
  align-items: center;
}

.imgFluid {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.center {
  text-align: center;
}

.imagesContainer {
  display: flex;
  justify-content: space-between;
}

.imagesContainer img {
  border-radius: 8px;
  object-fit: cover;
}

.radius {
  border-radius: 8px;
}

.modalTitle {
  font-size: 32px;
  font-family: "Tinos", serif;
}

.modalCaption {
  font-size: 16px;
  margin-top: 20px;
}

.count {
  text-align: center;
  margin-top: 20px;
  font-weight: bold;
}

.detailsContainer {
  max-width: 300px;
  margin: 50px auto 0;
}

.modalCloseButton {
  position:fixed;
  top:20px;
  right:30px;
  font-size:20px;
}

@media screen and (max-width: 991px) {
  .galleryModal > div:nth-of-type(2) > button {
    position: fixed;
  }
}

@media screen and (min-width: 992px) {
  .modalCaption {
    font-size: 16px;
    max-height: 300px;
    overflow: auto;
    margin-top: 30px;
  }
}

:global .closeButtonNone .ant-modal-close {
  display:none
}

.singlePage img {
  width: 100%;
  height: auto;
  max-height: 560px;
  object-fit: contain;
}

:global .single-gallery-wrapper .slick-track {
  display: flex;
  align-items: center;
}

@media screen and (min-width: 992px) {
  :global .ant-carousel .slick-initialized .slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
  }
}

