.activityItem {
	display: inline-block;
	width: 100%;
	background: #f7f6f3;
	color: #292929;
}

.description,
.date,
.address {
	padding: 13px 20px;
	flex: 1;
}

.address,
.date {
	border-right: 1px dashed #cccdcf;
}

.date {
	border-bottom: 1px dashed #cccdcf;
}

.address svg,
.date svg {
	color: #071013;
	opacity: 0.5;
}

.address span,
.date span {
	color: 
	#5C5C5C;
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	opacity: 0.75;
	line-height: 21px;
}

.event {
	display: inline-block;
	font-size: 10px;
	font-weight: 900;
	text-transform: uppercase;
	margin-bottom: 10px;
}

.title {
	font-family: 'Tinos',serif; 
	font-size: 24px;
	font-weight: bold;
	font-style: normal;
	line-height: 25px;
	color: #071013;
}

.activitydesc {
	width: 500px;
	opacity: 0.6;
	font-family: Roboto;
	font-size: 16px;
	line-height: 22px;
}

.tag {
	font-family: 'Roboto', sans-serif;
	font-size: 10px;
	font-weight: 600;
	line-height: 12px;
	text-align: center;
	position: absolute;
	top: 10px;
	right: 10px;
	width: 70px;
	padding-top: 5px;
	padding-bottom: 5px;
}

.clockIcon,
.pinIcon {
	display: inline-block;
	margin-right: 5px;
	font-size: 16px;
}

.pinIcon {
	position: relative;
	bottom: 0px;
}

.location {
	display: inline-block;
}

@media screen and (max-width: 991px) {
	.activityItem {
		display: flex;
		flex-direction: column-reverse;
	}

	.event {
		display: none;
	}

	.title {
		font-size: 16px;
		margin-top: 26px;
	}
	.title + p {
		display: none;
	}

	.tag {
		width: 66px;
		padding-top: 1px;
		padding-bottom: 1px;
	}

	.dateLocationWrapper {
		display: flex;
	}

	.date {
		display: flex;
		width: 95%;
	}

	.address {
		display: flex;
	}

	.date,
	.address {
		justify-content: center;
		align-items: center;
		border-top: 1px dashed #cccdcf;
	}

	.date {
		border-bottom: none;
	}

	.address {
		border-right: none;
	}
}