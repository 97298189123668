.title-wrapper {
    padding-left: 20px;
}

.btn-back {
    display: flex;
    align-items: center;
    height: 24px;
    margin-bottom: 20px;
    text-align: center;
    padding-left: 0;
}

.btn-back span {
    color: #FF595E;
    font-family: Roboto;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
}

.single-gallery-main-wrapper {
    padding: 30px 20px 60px 20px;
}

.gallery-title {
    color: #071013;
    font-family: "Tinos";
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
    margin: 0;
}

.images-count {
    height: 16px;
    width: 83px;
    color: #838789;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
    margin: 0;
}

.customButton {
    margin-top: 50px;
    padding-top: 16px;
    padding-bottom: 16px;
    font-family: Roboto, serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #FF595E;
    width: 100%;
    height: auto;
    border: 1px solid #F1F2F5;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 5px 6px -3px rgba(7,16,19,0.1);
}

@media screen and (min-width: 991px) {
    .single-gallery-main-wrapper {
        padding: 30px 20px 150px 20px;
    }
    .btn-back span {
        font-size: 24px;
    }

    .gallery-title {
        font-family: Lora;
        font-weight: bold;
        line-height: 30px;
    }

    .images-count {
        margin-top: 5px;
    }  
}