.filtersBtnWrapper {
  display: inline-block;
}

.filtersTitle {
  font-family: 'Roboto', sans-serif;
  color: #071013;
  display: inline-block;
  font-size: 16px;
  line-height: 19px;
  margin: 0 0 0 3px;
}

.searchTop input {
  padding: 16px;
}

.activitiesFiltersWrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  z-index: 3;
  overflow-y: auto;
}

.customButton {
  margin-bottom: 30px;
}