.featured-activity-card {
    background-color: #F7F6F3;
    padding: 12px;
}

.featured-activity-card:after {
    content: ' ';
    width: 30px;
    height: 100%;
    background-color: #F7F6F3;
    position: absolute;
    top: 0;
    right: -30px;
}

.featured-activity-card .ant-typography:not(h2) {
    font-size: 18px;
    font-family: Roboto;
}
