.resultsTable {
  font-family: "Tinos", serif;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 20px;
  text-align: right;
}

.loadingInProgress {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.header {
  background-color: #00bcd4;
}

.headerText {
  background-color: #00838f;
  color: #fff;
  font-size: 20px;
}

/* table tr:nth-child(2n) td {
  background-color: #f3f3f3;
  } */

.searchEmpty {
  min-height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.searchEmpty span {
  padding-top: 10px;
  font-size: 21px;
  color: #000;
  font-weight: 500;
}

.imageWrapper {
  max-height: 250px;
}

.imageWrapper img {
  display: block;
  height: 100%;
  max-height: 250px;
}

.resultsTable tbody > tr:hover {
  cursor: pointer;
}