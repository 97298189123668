.item {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.item:hover {
  cursor: pointer;
}

.box {
  margin-bottom: 24px;
}