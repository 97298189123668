:global .about-description h3 {
  align-self: flex-start;
}
:global .about-description a {
  opacity: 0.75;	
  color: #071013;	
  font-family: Roboto;	
  font-size: 18px;	
  line-height: 24px;
  text-decoration: underline;
}
:global .about-description hr.wp-block-separator {
  max-width: 650px;
}
:global .about-description h4,
:global .about-description h3 {
  display: block;
  width: 100%;
  align-self: center;
  max-width: 650px;
  margin: 0 auto 20px auto;
}
:global .about-description p,
:global .about-description ul,
:global .about-description ol {
  max-width: 100%;
  opacity: 0.75;	
  color: #071013;	
  font-family: Roboto;	
  font-size: 18px;	
  line-height: 27px;
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}
:global .about-description ul,
:global .about-description ol {
  padding-left: 30px;
  margin: 20px auto;
}
:global .about-description .wp-block-image {
  max-width: 650px;
  border-radius: 8px;
  margin: 30px auto;
  height: 260px;
  border-radius: 8px;
  overflow: hidden;
}
:global .about-description .wp-block-image img {
  /* width: 100%; */
  margin: auto;
  height: 260px;
  object-fit: cover;
  object-position: center;
  display: block;
}
:global .about-description .wp-block-image:first-child {
  max-width: 100%;
  margin: 30px auto;
  height: 475px;
  border-radius: 8px;
  overflow: hidden;
}
:global .about-description .wp-block-image:first-child img {
  width: auto;
  max-width: 100%;
  height: 475px;
  object-fit: cover;
  object-position: center;
  display: block;
}

:global .about-description .wp-block-embed-youtube .wp-block-embed__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* :global .about-description .wp-block-image:first-child figure {
  margin: 30px auto;
  height: 475px;
  border-radius: 8px;
  overflow: hidden;
}
:global .about-description .wp-block-image:first-child figure img {
  width: 100%;
  height: 475px;
  object-fit: cover;
  object-position: center;
  display: block;
} */
/* :global .strucne-sluzbe ol {
  padding-left: 30px;
}
:global .strucne-sluzbe .about-description .wp-block-image:first-of-type {
  margin-bottom: 30px;
  height: 260px;
  border-radius: 8px;
  overflow: hidden;
} */
/* :global .strucne-sluzbe .about-description .wp-block-image:first-of-type figure img {
  width: 100%;
  height: 260px;
  object-fit: cover;
  object-position: center;
  display: block;
}
:global .sluzba-za-zajednicke-poslove .about-description .wp-block-image:first-of-type {
  margin-bottom: 30px;
  height: 260px;
  border-radius: 8px;
  overflow: hidden;
} */
/* :global .sluzba-za-zajednicke-poslove .about-description figure.wp-block-image:first-of-type img {
  width: 100%;
  height: 260px;
  object-fit: cover;
  object-position: center;
  display: block;
} */

/* :global .o-biblioteci .about-description h4 {
  display: block;
  width: 100%;
  align-self: center;
} */
/* :global .o-biblioteci .about-description p,
:global .o-biblioteci .about-description h4,
:global .o-biblioteci .about-description ul {
  max-width: 650px;
  margin: auto;
} */
/* :global .o-biblioteci .about-description .wp-block-image:first-of-type {
  margin-bottom: 30px;
  height: 475px;
  border-radius: 8px;
  overflow: hidden;
}
:global .o-biblioteci .about-description .wp-block-image:first-of-type figure img {
  width: 100%;
  height: 475px;
  object-fit: cover;
  object-position: center;
  display: block;
} */
/* :global .o-biblioteci .about-description .wp-block-image {
  margin-bottom: 50px;
}
:global .o-biblioteci .about-description .wp-block-image:not(:first-of-type) {
  max-width: 650px;
  margin: 30px 0;
} */
:global .dokumenti {
  opacity: 0.75;	
  color: #071013;	
  font-family: Roboto;	
  font-size: 18px;	
  line-height: 27px;
}
:global .documents p {
  max-width: 100%;
}
:global .documents .wp-block-columns.has-4-columns {
  display: flex;
  justify-content: space-between;
  margin: 50px 0;
}
:global .documents a {
  opacity: 0.75;	
  color: #071013;	
  font-family: Roboto;	
  font-size: 16px;	
  line-height: 24px;
  text-decoration: underline;
}
:global .za-medije p,
:global .za-medije h3 {
  max-width: 100%;
}
:global .za-medije .about-description .wp-block-gallery {
  max-width: 100%;
  columns: 1;
  column-gap: 20px;
  padding-left: 0;
}
:global .za-medije .about-description .wp-block-gallery li {
  display: inline-block;
  vertical-align: top;
  margin: 0;
  margin-bottom: 20px;
  overflow: hidden;
}
:global .za-medije .about-description .wp-block-gallery li figure {
  position: relative;
}
:global .za-medije .about-description .wp-block-gallery li figure figcaption {
  display: block;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 25px 20px 20px;
  background: rgba(0, 0, 0, 0.5);
  text-align: right;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
:global .za-medije .about-description .wp-block-gallery li figure figcaption a {
  display: inline-block;
  color: white;
  font-weight: bold;
  position: relative;
  margin-left: 30px;
}
:global .za-medije .about-description .wp-block-gallery li figure figcaption a:before {
  content: "";
  display: block;
  width: 25px;
  height: 25px;
  background-image: url(../../assets/images/download-icon.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  left: -30px;
}
:global .za-medije .about-description .blocks-gallery-item img {
  width: 100%;
  border-radius: 8px;
}
:global .za-medije .about-description .wp-block-gallery li {
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, .3));
  transition: filter .25s ease-in-out;
}
:global .za-medije .about-description .wp-block-gallery li:hover {
  /* filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, .3)); */
}
:global .za-medije .about-description .wp-block-image:first-of-type {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  max-width: 100%;
  border-radius: 0;
  height: auto;
}
:global .za-medije .about-description .wp-block-image:first-of-type img {
  max-width: 100px;
  height: auto;
  margin: unset;
}
:global .za-medije .about-description .wp-block-image:first-of-type figcaption a {
  display: inline-block;
  font-weight: bold;
  position: relative;
  margin-left: 35px;
}
:global .za-medije .about-description .wp-block-image:first-of-type figcaption a:before {
  content: "";
  display: block;
  width: 25px;
  height: 25px;
  background-image: url(../../assets/images/download-icon.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  left: -30px;
}
/* :global .digitalni-omladinski-centar .about-description .wp-block-image:first-of-type {
  margin-bottom: 100px;
  height: 260px;
  border-radius: 8px;
  overflow: hidden;
}
:global .digitalni-omladinski-centar .about-description .wp-block-image:first-of-type figure img {
  height: 260px;
  object-fit: cover;
  display: block;
} */

@media only screen and (max-width: 1023px) and (min-width: 768px) {  
  :global .za-medije .about-description .wp-block-gallery {
    columns: 2;
  }
}
@media only screen and (min-width: 1024px) {
  :global .za-medije .about-description .wp-block-gallery {
    columns: 3;
  }
}
@media only screen and (max-width: 767px) {
  :global .documents .wp-block-columns.has-4-columns {
    display: flex;
    flex-direction: column;
  }
  :global .documents .wp-block-columns.has-4-columns .wp-block-column:not(:last-child) {
    margin-bottom: 20px;
  }
  :global .documents .wp-block-columns.has-4-columns .wp-block-column p {
    padding-left: 20px;
  }
  :global .documents .wp-block-columns.has-4-columns .wp-block-column p:first-of-type {
    padding-left: 0;
  }
}
