:global .headerDropdownWrapper {
    background-color: #fff;
    border: 1px solid #f3f3f6;
    border-radius: 5px;
    /* padding-top: 10px; */
    /* padding-right: 25px;
    padding-left: 25px; */
    text-align: left;
    /* position: relative; */
		/* top: 12px; */
		padding: 10px;
}

:global .headerDropdownWrapper:before,
:global .headerDropdownWrapper:after {
    bottom: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}
:global .headerDropdownWrapper:before {
	border-color: rgba(243, 243, 246, 0);
	border-bottom-color: #f3f3f6;
	border-width: 13px;
	margin-left: -13px;
}
:global .headerDropdownWrapper:after {
	border-color: rgba(255, 255, 255, 0);
	border-bottom-color: #fff;
	border-width: 12px;
	margin-left: -12px;
}

:global .headerDropdownWrapper.ant-menu-vertical .ant-menu-item > a,
:global .headerDropdownWrapper.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
	font-weight: bold;
}
/* :global .headerDropdownWrapper.ant-menu-vertical .ant-menu-item > a, */
:global .headerDropdownWrapper.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
	width: 230px;
	/* font-weight: bold; */
	font-weight: 700;
	font-size: 12px;
}

:global .headerDropdownWrapper .ant-menu-submenu-open.ant-menu-submenu-active {
	background-color: #ff595e;
}

:global .headerDropdownWrapper .ant-menu-submenu-open.ant-menu-submenu-active .ant-menu-submenu-title,
:global .headerDropdownWrapper .ant-menu-submenu-open.ant-menu-submenu-active .ant-menu-submenu-arrow {
	color: #fff;
}

:global .headerDropdownWrapper .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
:global .headerDropdownWrapper .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow:after {
	background: #ff595e;
}
:global .headerDropdownWrapper .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
:global .headerDropdownWrapper .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after {
	background: #fff;
}

:gloabl .additionalNavItemSeparator {
	display: inline-block;
	position: absolute;
	right: 0;
	top: 10px;
	width: 1px;
	height: 25px;
	background-color: #e8e8e8;
}