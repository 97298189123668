.headingSection {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1em;
	border-bottom: 1px solid #f1f2f5;
}
.heading {
	height: 25px;
	width: 360px;
	color: #071013;
	font-family: "Tinos";
	font-size: 20px;
	font-weight: bold;
	line-height: 25px;
}
.row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1em;
	border-top: 1px solid #f1f2f5;
}
.info {
	/* height: 20px; */
	width: 360px;
	opacity: 0.75;
	color: #071013;
	font-family: Roboto;
	font-size: 18px;
	line-height: 20px;
}
.actions {
	display: flex;
	justify-content: center;
}
.actionItem {
	margin: 10px;
	height: 40px;
	width: 40px;
	border-radius: 20px;
	background-color: #ffffff;
	box-shadow: 0 5px 6px -3px rgba(7, 16, 19, 0.1);
	display: inline-block;
	line-height: 40px;
	text-align: center;
	color: #ff595e;
	cursor: pointer;
}
.title {
	opacity: 0.3;
	color: #071013;
	font-family: Roboto;
	font-size: 14px;
	font-weight: 900;
	line-height: 31px;
}

.singlelink {
	margin-right: 0;
	transform: scale(.8);
	border-bottom: none;
	text-decoration: underline;
}
.singlelink > a {
	color: #071013;
	opacity: .5;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
}

.actionItem {
	margin: 10px;
	height: 40px;
	width: 40px;
	border-radius: 20px;
	background-color: #ffffff;
	box-shadow: 0 5px 6px -3px rgba(7, 16, 19, 0.1);
	display: inline-block;
	line-height: 40px;
	text-align: center;
	color: #ff595e;
	cursor: pointer;
}

@media only screen and (max-width: 991px) {
	.row {
		flex-direction: column;
		align-items: flex-start;
	}

	.author {
		opacity: 0.75;
		color: #071013;
		font-family: "Tinos";
		font-size: 14px;
		font-weight: bold;
		line-height: 20px;
	}

	.ganre {
		opacity: 0.5;
		color: #071013;
		font-family: Roboto;
		font-size: 10px;
		line-height: 20px;
	}

	.bookInfo {
		opacity: 0.75;
		color: #071013;
		font-family: Roboto;
		font-size: 16px;
		line-height: 20px;
		width: 100%;
	}

	.title {
		opacity: 0.3;
		color: #071013;
		font-family: Roboto;
		font-size: 12.8px;
		font-weight: bold;
		line-height: 24px;
		text-align: center;
	}
}
