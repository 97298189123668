.title {
	font-family: 'Tinos';
	font-size: 32px;
	line-height: 40px;
	font-weight: 400 !important;
}

.cardTitle {
	color: #071013;
	font-family: 'Tinos';
	font-size: 36px !important;
	line-height: 50px !important;
	font-weight: normal !important;
}

.link {
	margin-right: 30px;
}

.image {
	width: 100%;
}

.imageWrapper {
	/* position: relative; */
	position: absolute;
	height: 400px;
	background-size: cover;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
}

.imageBackdrop {
	position: absolute;
	height: 400px;
	background-size: cover;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
	background: rgba(0, 195, 245, 0.65);
}

.featuredImageInfo {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	padding: 60px 30px;
}

.featuredImageDay {
	height: 17px;
	width: 59px;
	opacity: 0.8;
	color: #ffffff;
	font-family: Roboto;
	font-size: 14px;
	font-weight: 900;
	line-height: 17px;
	text-transform: uppercase;
}

.featuredImageDate {
	height: 60px;
	color: #ffffff;
	font-family: 'Tinos';
	font-size: 39px;
	line-height: 60px;
	text-transform: capitalize;
}

.featuredImagePlace {
	height: 54px;
	width: 320px;
	color: #ffffff;
	font-family: Roboto;
	font-size: 18px;
	line-height: 27px;
	margin-top: 20px;
}

.featuredImageDivider {
	display: inline-block;
	height: 6px;
	width: 100px;
	background-color: white;
}

.icon {
	margin-left: 6px;
}

.readMore {
	margin-left: auto;
	display: block;
}

.btnWrapper {
	/* margin-top: 22px;
	display: flex;
	align-items: flex-end; */

	margin-top: 22px;
	display: flex;
	position: absolute;
	bottom: 1rem;
	width: 90%;
}

.pageBtn {
	height: 40px;
	width: 40px;
	border-radius: 20px;
	background-color: #ffffff;
	box-shadow: 0 5px 6px -3px rgba(7, 16, 19, 0.1);
	display: inline-block;
	line-height: 40px;
	text-align: center;
	color: #ff595e;
	cursor: pointer;
}

.pageBtn:hover {
	color: black;
}

.mLeft {
	margin-left: 20px;
}
