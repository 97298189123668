.menuSubTitle {
	display: inline-block;
	color: #292929;
	font-family: Roboto, serif;
	font-size: 18px;
	font-weight: bold;
	line-height: 25px;
	margin-bottom: 16px;
}
.dropDownTitle {
	color: #888a8c;
	font-family: Roboto;
	font-size: 16px;
	line-height: 40px;
}

:global .userGuide .ant-menu-item-group-title {
	color: #838789 !important;
	font-family: Roboto !important;
	font-size: 10px !important;
	font-weight: 900 !important;
	letter-spacing: 1px !important;
	line-height: 12px !important;
	text-transform: uppercase !important;
	border-bottom: 1px solid #f1f2f5;
}
:global .userGuide .ant-menu-item {
	color: #888a8c;
	font-family: Roboto;
	font-size: 16px;
	line-height: 40px;
}
:global .userGuide .ant-menu-item.ant-menu-item-selected {
	font-weight: bold;
	color: #292929;
}
:global .userGuide .ant-menu-item-group-list .indentedMenuItem.ant-menu-item {
	display: inline-block;
	padding-left: 45px !important;
	font-size: 15px;
}
