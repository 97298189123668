.magazineTitle {
  display: none;
}
.headerImg {
  height: 260px;
  border-radius: 8px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 100px;
}
.magazineDescWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 100px;
}
:global .magazine-description {
  display: inline-block;
  max-width: 650px;
  opacity: 0.75;
  color: #071013;
  font-family: Roboto;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 991px) {
  .headerImg {
    height: 100px;
    margin-bottom: 30px;
  }
  .magazineTitle {
    display: block;
    color: #292929;
    font-family: "Tinos";
    font-size: 30px;
    font-weight: bold;
    line-height: 40px;
    margin-bottom: 20px;
  }
  .magazineDescWrapper {
    margin-bottom: 50px;
  }
}