.recommended_by {
	opacity: 0.75;
	color: #292929;
	font-family: Roboto;
	font-size: 14px;
	font-weight: bold;
	line-height: 17px;
	margin-left: 8px
}

.invisible_number {
	position: absolute;
	top: 0;
	right: 0;
	height: 150px;
	width: 150px;
	font-family: "Adobe Caslon Pro";
	color: rgba(7,16,19,0.05);
	font-size: 150px;
	font-weight: 600;
	line-height: 180px;
}

.selectedTitle {
	display: flex;
	font-size: 25px;
  font-weight: 400;
	font-family: "Tinos";
	margin-bottom: 32px;
	color: #000;
}

.yearSelect {
	border: 2px solid red;
}

.bookItemActive {
	cursor: pointer;
	display: flex;
	animation: fadeIn 2.2s;
}

.imageContainer {
	margin: 0 auto;
	box-shadow: 0 10px 15px -5px rgba(7, 16, 19, 0.2);
	width: 146px;
	height: 230px;
}

.details {
	margin-left: 20px;
	height: 230px;
	overflow: hidden;
}

.indexActive {
	position: absolute;
	margin-top: 0 !important;
	top: -5%;
	right: 0;
	z-index: -2;
	color: rgba(7, 16, 19, 0.03) !important;
	font-family: "Adobe Caslon Pro" !important;
	font-size: 150px !important;
	font-weight: 600;
	line-height: 180px !important;
}

.author {
	opacity: 0.5;
	color: #071013;
	font-family: Roboto;
	font-size: 17px;
	line-height: 25px;
	margin-bottom: 18px;
}

.mrb__book__col {
	width: 450px;
	height: 230px;
	margin-bottom: 100px;
}

.mrb__loadmore {
	margin-top: 32px;
  padding-top: 16px;
  padding-bottom: 16px;
  font-family: Roboto, serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #FF595E;
  width: 100%;
  height: auto;
  border: 1px solid #F1F2F5;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 5px 6px -3px rgba(7,16,19,0.1);
}

.genre {
	color: rgba(0, 0, 0, 0.2);
	font-size: 16px;
	text-transform: uppercase;
	font-weight: 700;
	margin-bottom: 0;
}

@media screen and (max-width: 576px) {
	.mrb__book > div > div > div {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		overflow: scroll;
		height: 620px;
	}

	.mrb__book__col {
		flex: 1;
  	width: 336px;
	}

	.mrb__book__col > div {
		flex-direction: column;
	}

	.imageContainer {
		height: 344px;
		width: 224px;
		margin-bottom: 31px;
	}

	.details {
		margin-left: 0;
	}

	.selectedTitle {
		flex-direction: column;
	}

	.invisible_number {
		display: none;
	}
}
