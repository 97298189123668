.mainTitle {
  display: none;
  color: #292929;	
  font-family: "Tinos";	
  font-size: 30px;	
  font-weight: bold;	
  line-height: 40px;
}
@media screen and (max-width: 991px) {
  .mainTitle {
    display: block;
  }
}