.wrapper {
    font-family: 'Roboto', sans-serif;
    padding-top: 30px;
    border-top: 3px solid #F1F2F5;
    margin-top: 30px;
}

.menu {
    border-right: none;
}

.item {
    background-color: #F7F6F3;
    border-radius: 4px;
}