.main {
    background-color: #00A1C5;
    padding: 30px;
}

.wrapper {
    margin-right: 118px;
}

.title {
    font-family: "Tinos";
    color: #FFFFFF !important;
    font-size: 36px !important;
    line-height: 40px;
    font-weight: 400 !important;
    height: 96px;
}

.btnWrapper {
    margin-top: 48px;
}

.date {
    opacity: 0.8;
    color: #FFFFFF;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 900;
    line-height: 17px;
    display: block;
    margin-bottom: 5px;
}

.time {
    color: #FFFFFF;
    font-family: "Tinos";
    font-size: 36px;
    line-height: 30px;
    display: block;
    margin-bottom: 18px;
}

.shortDesc {
	max-width: 320px;
	color: #FFFFFF;
	font-family: "Tinos";
	font-size: 18px;
    line-height: 27px;
    height: 54px;
}

.image {
    position: absolute;
    background-size: cover;
    width: 330px;
    height: 330px;
    left: 60%;
    top: 45%;
}