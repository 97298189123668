:global .facens--single-item {
    width: 650px;
    margin: auto;
}

:global .facens--single-item * {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    line-height: 27px;
    color: #071013;
}

:global .facens--single-item .aligncenter {
    text-align: center;
}

:global .facens--single-item h1,
:global .facens--single-item h2,
:global .facens--single-item h3,
:global .facens--single-item h4,
:global .facens--single-item h5,
:global .facens--single-item h6 {
    font-weight: 900;
}

:global .facens--single-item h3 {
    font-size: 20px;
}

:global .facens--single-item img + figcaption {
    font-size: 16px;
    line-height: 24px;
    color: rgba(7,16,19,0.5);
}

:global .facens--single-item p > em {
    color: rgba(7,16,19,0.7);
}

:global .facens--single-item .wp-block-separator {
    border: none;
    border-bottom: 1px solid #f1f2f5;
}

:global .quotes-about-fortress figure {
    display: inline-block;
}
:global .quotes-about-fortress img {
    max-width: 200px;
}

:global .facens--single-item.artist hr {
    margin: 50px 0;
}

:global .facens--single-item.artist h3 {
    margin-bottom: 30px;
}

:global .facens--single-item.artist .aligncenter img {
    max-width: 300px
}

:global .facens--single-item.artist .wp-block-columns {
    display: flex;
}

:global .facens--single-item.artist .wp-block-columns .wp-block-image {
    margin-right: 30px;
}

:global .facens--single-item.artist .wp-block-columns .wp-block-image img {
    max-width: 200px;
}

:global .facens--single-item.artist p em {
    word-break: break-all;
}

:global .facensMenu .ant-menu-item-group-title {
    color: #838789;	
    font-family: Roboto;	
    font-size: 10px;	
    font-weight: 900;	
    letter-spacing: 1px;	
    line-height: 12px;
    text-transform: uppercase;
  }
:global .facensMenu .ant-menu-item {
  color: #888A8C;	
  font-family: Roboto;	
  font-size: 16px;	
  line-height: 40px;
}
:global .facensMenu .ant-menu-item.ant-menu-item-selected {
  font-weight: bold;
  color: #292929;
}
:global .facensMenu .ant-menu-item-group-list h4 {
    display: inline-block;
    padding-left: 0;
    font-size: 15px;
}
:global .facensMenu .ant-menu-item-group-list .indentedMenuItem.ant-menu-item {
  display: inline-block;
  padding-left: 45px !important;
  font-size: 15px;
}

:global .container-publisher {
    width: 100%;
}

:global .heading-publisher {
    margin-bottom: 3rem;
    font-size: 2rem;
    font-weight: bold;
}

:global .has-text-align-center {
    text-align: center;
}

@media screen and (min-width: 992px) {
    :global .quotes-about-fortress > figure + div {
        display: inline-block;
        vertical-align: top;
        width: 63%;
        margin-left: 30px;
    }
}

@media screen and (max-width: 991px) {
    .singleItem {
        width: 100%;
    }

    :global .facens--single-item.artist figure {
        display: block !important;
        text-align: center;
    }
}