.tabpanel > div > div {
  color: #b4b7b8;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
}

.allTitles > div {
  margin: 0;
}

:global .ant-tabs-nav > div > .ant-tabs-tab-active {
  color: #000;
  font-weight: 900;
}

:global .ant-tabs-nav > div > .ant-tabs-tab-active:hover {
  color: #000;
  font-weight: 900;
}