.title {
	color: #292929;
	font-family: "Tinos";
	font-size: 31px !important;
    line-height: 30px;
    margin-bottom: 40px !important;
    margin-top: 50px !important;
    font-weight: normal !important;
    text-align: center;
}

.image {
    width: 100%;
    display: block;
    margin-bottom: 20px;
}

@media screen and (max-width: 991px) {
    .image {
        width: 50%;
        margin: 0 auto 20px auto;
    }
}