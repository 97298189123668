.content {
  background-color: #F7F6F3;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-family: "Tinos";
  font-size: 32px;
  margin: 0;
}
:global .popularActivities .ant-btn-circle {
  height: 40px;	
  width: 40px;	
  border-radius: 20px;	
  background-color: #FFFFFF;	
  box-shadow: 0 5px 6px -3px rgba(7,16,19,0.1);
  border: none;
}
:global .popularActivities .ant-btn-circle .anticon {
  line-height: 1.1;
  box-sizing: border-box;	
	
  color: #FF595E;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 0;
}

.actions {
  display: flex;
  justify-content: center;
}

.actionItem {
  margin: 10px;
}

.slideContainer {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width:991px) {
  .actionItem {
    /* display: none; */
  }
  .header {
    padding: 0 30px;
    margin-bottom: 50px;
  }
  .allActivities {
    margin-right: 0;
  }
}

@media scren and (max-width: 576px) {
  .actionItem {
    display: none;
  }
}
