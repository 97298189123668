.contact-modal .ant-modal-content, .contact-modal .ant-modal-header {
    background-color: #F7F6F3;
}

.contact-modal .ant-modal-content {
    padding: 6px 12px 12px;
}

.contact-modal .ant-modal-header {
    border-bottom: none;
}

.contact-modal .ant-modal-title {
    font-family: "Tinos";
    font-size: 32px;
    line-height: 40px;
    border-bottom: 1px solid #D1D3D3;
    padding-bottom: 10px;
}

.contact-modal .ant-modal-body .ant-form-item-label {
    padding: 0;
}

.contact-modal .ant-modal-body .ant-form-item {
    margin-bottom: 18px;
}

.contact-modal .ant-modal-body {
    font-family: Roboto;
    padding-bottom: 0;
}

.contact-modal .ant-modal-body .ant-form-item-required:before {
    margin-right: 1px;
    color: #FF595E;
}

.contact-modal .ant-modal-body .ant-form-item-label {
    color: #5C5C5C;
    font-size: 15px;
    font-weight: bold;
    line-height: 21px;
}

.contact-modal .ant-modal-footer {
    border-top: none;
}

.contact-modal .ant-modal-footer > div {
    display: flex;
}

.contact-modal .ant-modal-footer button {
    flex-grow: 1;
    height: 40px;
}

.ant-list-header {
    border-bottom: none;
}

.contact-modal-adresa {
    color: #848484;
    font-family: Roboto;
    font-size: 15px;
    letter-spacing: 0.5px;
    line-height: 18px;
    position: absolute;
    top: -10px;
}

.ant-modal-body {
    position: relative;
}